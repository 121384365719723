import _ from 'lodash';
import moment from 'moment';

export default (providerList) => {
  return _.map(providerList, (provider) => {
    return _.assign({}, provider, {
      paymentTotal: 0,
      onlineScripts: 0,
      procedureFees: 0,
      gstTotal: 0,
      grossGst: 0,
      earliestTransactionDate: moment(),
      paymentData: [],
      gstLines: [],
      directBillingsTotal: 0,
      providerId: provider.id,
    });
  });
};
