import _ from 'lodash';
import moment from 'moment';

import Constants from '../../../../../../constants';

const directBillings = [
  { paymentMethod: 'direct credit', accountType: 'dva' },
  { paymentMethod: 'direct credit', accountType: 'medicare' },
  { paymentMethod: 'eft', accountType: 'private' },
  { paymentMethod: 'deposit allocation - eft', accountType: 'private' },
];

const isDirectBillingsPayment = ({
  accountType,
  billedTo,
  paymentMethod,
  provider,
  transactionDate,
}) => {
  if (_.isNull(provider.directBillingsStartDate)) {
    return false;
  }

  const directBillingsStartDate = moment(
    provider.directBillingsStartDate,
    Constants.DATE_FORMAT_YEAR_FIRST
  );
  const paymentDate = moment(transactionDate, Constants.DATE_FORMAT);

  if (paymentDate.isBefore(directBillingsStartDate)) {
    return false;
  }

  // An additional check is required here for Workcover billings. Generally they
  // should be direct (as they are being billed through Tyro Health), but
  // a manual override process has been put in place to handle exceptions.
  // The Account Type will be manually overriden in the payments file, changing
  // it from "Other" to "Other - Indirect".
  // At this stage, TAC payments are still being handled as Indirect billings.
  // In addition to the above, the manual overide process has been modified to
  // just check for either '- indirect' or '- direct' in the Account Type
  if (
    _.toLower(paymentMethod) === 'direct credit' &&
    _.startsWith(_.toLower(accountType), 'other')
  ) {
    if (_.toLower(billedTo) === 'tac') {
      if (_.toLower(accountType) === 'other') {
        return true;
      }
      return false;
    }
    if (_.toLower(accountType) === 'other - indirect') {
      return false;
    }
    return true;
  }

  const directBillingsPayment = _.find(directBillings, {
    paymentMethod: _.toLower(_.trim(paymentMethod)),
    accountType: _.toLower(_.trim(accountType)),
  });

  return !_.isUndefined(directBillingsPayment);
};

export default isDirectBillingsPayment;
